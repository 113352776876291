import React, {useEffect, useState} from "react";
import {
    Box,
    Button,
    CircularProgress,
    Dialog,
    DialogContent,
    IconButton,
    MenuItem, Stack,
    TextField,
    Typography
} from "@mui/material";
import {useParams} from "react-router-dom";
import {firebase} from "../firebase/clientApp";
import {Edit} from "@mui/icons-material";
import axios from "axios";
import {API_URL} from "../config";
import {CustomAlert} from "./shared/CustomAlert";

export const DisplayName = ({userData, setUserData}) => {
    const {id} = useParams()
    const [state, setState] = useState({displayName: '', teamName: ''})
    const [error, setError] = useState(false)
    const [setting, setSetting] = useState(false)
    const [editing, setEditing] = useState(false)

    useEffect(() => {
        if (userData) {
            setState(userData)
        }
    }, [userData])

    const handleSetDisplayName = (event) => {
        setState({...state, displayName: event.target.value})
    }

    const handleSetTeam = (event) => {
        setState({...state, teamName: event.target.value})
    }

    const submitDisplayName = async (event) => {
        event.preventDefault()
        setSetting(true)
        let uid = firebase.auth().currentUser.uid
        await axios.post(`${API_URL}/events/${id}/users`, {user: uid, displayName: state.displayName, teamName: state?.teamName ?? ""})
            .then(res => {
                    setUserData({displayName: state.displayName ?? '', teamName: state.teamName ?? ''})
                    setError("")
                    setEditing(false)
            })
            .catch((err) => {
                console.log("NickName già preso!!!", err?.response?.data?.message ?? "There was an error")
                setError(err?.response?.data?.message ?? "There was an error")
            })
            .finally(() => {
                setSetting(false)
            })
    }

    return (
        <Box my={1} px={3}>
            {
                (userData.displayName==='' || !userData.displayName) ?
                    <Box>
                        <Typography textAlign={'center'} fontWeight={'bold'} fontSize={20} sx={{pb: 2}}>
                            Inserisci nome e cognome per interagire
                        </Typography>
                        <form onSubmit={submitDisplayName}>
                            <TextField
                                fullWidth
                                required
                                inputProps={{style: {fontSize: '1.2rem'}}}
                                placeholder="Nome e cognome"
                                id="displayName"
                                value={state.displayName}
                                onChange={handleSetDisplayName}/>
                            <Box pt={2}>
                                {setting ? <CircularProgress/> :
                                    <Button variant={'wordcloud'} type={'submit'}
                                            disabled={
                                                (editing && userData.displayName === state.displayName) || (!state.displayName)
                                            }>
                                        Invia
                                    </Button>}
                                {editing && <Button sx={{color: 'white'}} onClick={() => setEditing(false)}>
                                    Annulla
                                </Button>}
                                <CustomAlert open={!!error} message={error} setOpen={setError} severity={'error'}/>
                            </Box>
                        </form>
                    </Box>
                    :
                    <Box>
                        <Typography textAlign={'center'} fontSize={18} fontWeight={'lighter'}>
                            Ciao, <b>{userData.displayName}</b>
                            <IconButton size={'small'} onClick={() => setEditing(!editing)}>
                                <Edit/>
                            </IconButton>
                        </Typography>
                        <Typography textAlign={'center'} fontSize={20}>
                        {userData.teamName ? `TEAM: #${userData.teamName}` : ''}
                        </Typography>

                        <Dialog open={editing} handleClose={() => setEditing(false)}>
                            <DialogContent>
                                <form onSubmit={submitDisplayName}>
                                    <TextField
                                        fullWidth
                                        required
                                        inputProps={{style: {fontSize: '1.2rem'}}}
                                        placeholder="Nome e cognome"
                                        id="displayName"
                                        value={state.displayName}
                                        onChange={handleSetDisplayName}/>
                                    <Stack direction={'row'} pt={2} alignItems={'center'} spacing={2}>
                                        {editing && <Button sx={{color: 'white'}} onClick={() => setEditing(false)}>
                                            Annulla
                                        </Button>}
                                        {setting ? <CircularProgress/> :
                                            <Button variant={'wordcloud'} type={'submit'}
                                                    disabled={
                                                        (editing && userData.displayName === state.displayName) || (!state.displayName)
                                                    }>
                                                Invia
                                            </Button>}
                                        <CustomAlert open={!!error} message={error} setOpen={setError}
                                                     severity={'error'}/>
                                    </Stack>
                                </form>
                            </DialogContent>
                        </Dialog>
                    </Box>
            }

        </Box>
    )
}
