export const API_URL = "https://api.secondstage.app"
//export const API_URL = "http://localhost:8080"
//export const TOOLPRESS_API = "https://api-tool-press-yn26wrnl4q-ey.a.run.app"
export const TOOLPRESS_API = "https://toolpress-api.2ndstage.app"

export const THEME_CONFIG = {
    mode:"dark",
    gradients: {
        default: ['#ffffff', '#ffffff'],
        home_question: ['#ffffff', '#ffffff'],
        applausometro: ['rgba(229,99,74,0)', 'rgba(229,68,138,0)'],
        siNo:['#3a4de4', '#00d8a1'],
        sentiment: ['#3a4de4', '#00d8a1'],
        wordcloud: ['#ffffff', '#ffffff'],
        qna: ['#EA4D95', '#E76E51'],
    },
    primary: '#fae54f',
    secondary: '#263238',
    accent: '#fae54f',
    text: '#fff',
    background:"#e9000d",
    poll:{
        default:"#ffffff",
        answered:"#24ae8e",
        correct: '#3A722D',
        wrong: '#DB0A16',
    }
}