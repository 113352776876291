import React, {useState} from 'react'
import {Box, Button, Collapse, Container, Stack, Typography, CircularProgress, useTheme} from "@mui/material";
import {Delete} from "@mui/icons-material";
import axios from "axios";
import {useParams} from "react-router-dom";
import {API_URL} from "../../../config";
import {firebase} from "../../../firebase/clientApp";
import {CustomAlert} from "../../shared/CustomAlert";

export const SendPhotoQuestion = ({elementId}) => {
    const {id} = useParams()
    const [foto, setFoto] = useState(null)
    const [url, setUrl] = useState('')
    const [success, setSuccess] = useState(false)
    const [sending, setSending] = useState(false)
    const theme = useTheme()

    const handleCompileFoto = (e) => {
        const [f] = e.target.files
        setFoto(f)
        setUrl(URL.createObjectURL(f))
    }

    const sendPhoto = async () => {
        setSending(true)
        const formData = new FormData();
        formData.append('file', foto);
        formData.append('user', firebase.auth().currentUser.uid);
        await axios.post(`${API_URL}/answer/${id}/${elementId}/photo`, formData)
            .then(() => {
                setSuccess(true)
                setFoto(null)
                setUrl('')
            })
            .catch(() =>{})
        setSending(false)
    }

    return(
      <Container>
          <CustomAlert open={success} setOpen={setSuccess} severity={'success'} message={'Foto inviata con successo'}/>
          <form style={{padding: '1rem 0'}}>
              <Stack direction={"column"} spacing={4}>
                  <Box sx={{
                      width: '100%',
                      aspectRatio: '1/1',
                      fontWeight: '600',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      //borderRadius: '3rem',
                      border: `2px dashed ${theme.palette.primary.main}`,
                      padding: '1rem'
                  }} component="label">
                      {foto === null ?
                          <Box>
                              <Typography variant={'h5'} color={'#eceff1'}>Scegli una foto</Typography>
                              <input type="file" hidden onChange={handleCompileFoto}/>
                          </Box> :
                          <Box>
                              <Box sx={{width:'11rem', height:'11rem', margin:'auto'}}>
                                  <img style={{width: '100%', height: '100%', objectFit:'cover'}} src={url}/>
                              </Box>
                              <Box>
                                  <Button startIcon={<Delete/>} onClick={() => {
                                      setTimeout(() => {
                                          setFoto(null)
                                          setUrl('')
                                      }, 100)
                                  }}>
                                      Cancella
                                  </Button>
                              </Box>
                          </Box>
                      }
                  </Box>
                  <Box sx={{textAlign:'center'}}>
                      {sending ? <CircularProgress/> : <Button onClick={sendPhoto} disabled={foto === null}
                               variant={'default'}>
                          Invia
                      </Button>}
                  </Box>
              </Stack>
          </form>
      </Container>
  )
}
