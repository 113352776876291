import React from "react";
import {Box, Slide, Stack, Typography} from "@mui/material";

import axios from "axios";
import {useParams} from "react-router-dom";
import {emojisplosion} from "emojisplosion";
import {API_URL} from "../config";
import {useMediaQuery} from "@material-ui/core";
import heart from '../assets/images/reactions/heart.png'
import dislike from '../assets/images/reactions/dislike.png'
import clap from '../assets/images/reactions/clap.png'
import pleshDark from "../theme/pleshDark";

const ReactionsLive = ({url, idPlaying, eventPlaying, user}) => {
    const {id} = useParams()

    async function postDataApplausi(e, type, emoji) {
        emojisplosion({
            emojiCount:5,
            emojis: [emoji],
            position: () => ({
                x: e.clientX,
                y: e.clientY,
            }),
        })
        await axios.post(`${API_URL}/${id}/reactions?type=${type}`, {user: user.uid})
            .then(r => {
                navigator.vibrate(200)
            })
            .catch(error => console.log(error))
    }

    const ButtonItem = ({type, img, emoji, color}) => (
        <Box id='clapButton' disabled={!eventPlaying} onClick={(e) => postDataApplausi(e, type, emoji)}
             justifyContent={'center'} alignItems={'center'}
             sx={{
                 //bgcolor: pleshDark.palette.accent.main,//color,
                 cursor: 'pointer',
             }}x
        >
            <img style={{margin: 'auto', height:'85px',}} src={img} />
        </Box>
    )

    const matches = useMediaQuery('(max-height:680px)');

    return (
        <Stack px={2} sx={{zIndex:100}} alignItems={'center'} position={'absolute'} bottom={18} left={0} right={0}
        >
            <Typography gutterBottom variant={'h5'}>Manda una REACTION!</Typography>
            <Slide in direction={"up"} timeout={1000}>
                <Stack direction={'row'} display={'flex'} justifyContent={'space-evenly'} spacing={1} width={'100%'}>
                    {
                        reactions.map((reaction) => (
                            <ButtonItem key={reaction.id} type={reaction.id} img={reaction.img} emoji={reaction.emoji} color={reaction.color}/>
                        ))
                    }
                </Stack>
            </Slide>
        </Stack>
    )
}
export default ReactionsLive

const reactions = [
    {
        id: 'applausi',
        emoji: '👏',
        img: clap
    },
    {
        id: 'heart',
        emoji: '❤️',
        img: heart,
        color: '#fde357'
    },
    {
        id: 'dislike',
        emoji: '👎',
        img: dislike
    }
]